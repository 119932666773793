import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxTableService } from '../ngx-table.service';

@Component({
  selector: 'ngx-table-search',
  template: `
    <form (submit)="submit()" [formGroup]="formData">
      <div class="form-group flex items-center gap-2">
        <label for="searchTerm" class="col-form-label col-auto">Search</label>
        <input class="!w-auto" type="search" id="searchTerm" pInputText name="searchTerm" (change)="submit()" formControlName="searchTerm" />
      </div>
    </form>
  `,
})
export class NgxTableSearchComponent implements OnInit {
  public formData: UntypedFormGroup | undefined;

  constructor(private formBuilder: UntypedFormBuilder, public ngts: NgxTableService<any>) {}

  public submit() {
    if (this.formData?.valid) {
      try {
        this.ngts.onSearch(this.formData.get('searchTerm').value);
      } catch (e) {
        console.log(e);
      }
    }
  }

  ngOnInit() {
    this.formData = this.formBuilder.group({
      searchTerm: ['', [Validators.required, Validators.minLength(3)]],
    });
  }
}
