import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.production) {
  Sentry.init({
    dsn: 'https://3313217be8084adf8fa6fc5d9cd367e3@sentry.io/1426178',
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: ['localhost', 'aw.minnich-mfg.com'],
    tracesSampleRate: 0.5,
  });
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
