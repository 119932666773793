import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxTableService } from '../ngx-table.service';

@Component({
  selector: 'ngx-table-pagination',
  template: `
    <p-paginator
      (onPageChange)="ngts.updatePaginatorState($event)"
      [first]="first"
      [rows]="rows"
      [totalRecords]="total"
      [rowsPerPageOptions]="[5, 10, 25, 50, 100, 200]"
    ></p-paginator>
  `,
  styles: [
    `
      ::ng-deep ul {
        margin-bottom: 0px !important;
      }
    `,
  ],
})
export class NgxTablePaginationComponent implements OnInit, OnDestroy {
  public first: number = 0;
  public rows: number = 10;
  public total: number;
  public pageSize: number;
  private subscriptions: Subscription = new Subscription();

  constructor(public ngts: NgxTableService<any>) {
    // this.first = (page - 1) * this.itemsPerPage;
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.ngts.total.subscribe((d) => (this.total = d)));
    this.subscriptions.add(this.ngts.pageSize.subscribe((d) => (this.pageSize = d)));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
