import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleService } from '@mm-mono/modules-client/google';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { PixelService } from 'ngx-multi-pixel';
import { NewsletterSignupComponent } from './views/public/home/newsletter-signup.component';

@Component({
  selector: 'app-root',
  template: `
    <header></header>
    <router-outlet></router-outlet>
    <footer class="bg-neutral-900 py-5"></footer>
  `,
  styles: [],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private googleService: GoogleService,
    private consentService: NgcCookieConsentService,
    private dialogsService: NgxDialogsService,
    private pixel: PixelService
  ) {
    this.pixel.initialize();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.googleService.gtag('config', this.googleService.GA_TRACKING_ID, { page_path: event.urlAfterRedirects });
        this.googleService.gtag('config', this.googleService.AW_CONVERSION_ID, { page_path: event.urlAfterRedirects });
      }
    });
  }

  public ngOnInit(): void {
    setTimeout(() => {
      if (localStorage.getItem('newsletterSignupResult') === 'false') return;
      this.dialogsService
        .component(NewsletterSignupComponent, {
          width: '50vw',
          modal: true,
          showHeader: false,
          styleClass: 'rounded-2xl',
          contentStyle: { margin: '0', padding: '0', 'border-radius': '1rem' },
          breakpoints: {
            '1024px': '80vw',
            '1280px': '70vw',
          },
        })
        .subscribe();
    }, 1000);
  }
}
