import { Injectable } from '@angular/core';
import { debugStream } from '@mm-mono/utilities';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog/dynamicdialog-config';
import { Observable } from 'rxjs';
import { AlertDialog, AlertModalOptions, ConfirmDialog, ConfirmModalOptions, Fields, PromptDialog, PromptModalOptions } from './dialogs';

@Injectable()
export class NgxDialogsService {
  private dialogDefaults: DynamicDialogConfig = {
    modal: true,
    closable: false,
  };

  constructor(private dialogService: DialogService) {}

  public component<T = unknown>(content: { new (...args: any[]) }, dialogConfig?: DynamicDialogConfig): Observable<T> {
    return this.dialogService
      .open(content, {
        ...this.dialogDefaults,
        ...dialogConfig,
      })
      .onClose.pipe(debugStream(content.name));
  }

  public confirm(confirmModalOptions: ConfirmModalOptions, dialogConfig?: DynamicDialogConfig): Observable<boolean> {
    return this.dialogService
      .open(ConfirmDialog, {
        ...this.dialogDefaults,
        header: confirmModalOptions.header,
        ...dialogConfig,
        data: { ...confirmModalOptions },
      })
      .onClose.pipe(debugStream(ConfirmDialog.name));
  }

  public alert(alertModalOptions: AlertModalOptions, dialogConfig?: DynamicDialogConfig): Observable<void> {
    return this.dialogService
      .open(AlertDialog, {
        ...this.dialogDefaults,
        header: alertModalOptions.header,
        ...dialogConfig,
        data: { ...alertModalOptions },
      })
      .onClose.pipe(debugStream(AlertDialog.name));
  }

  public prompt(promptModalOptions: PromptModalOptions, dialogConfig?: DynamicDialogConfig): Observable<Fields> {
    return this.dialogService
      .open(PromptDialog, {
        ...this.dialogDefaults,
        header: promptModalOptions.header,
        ...dialogConfig,
        data: { ...promptModalOptions },
      })
      .onClose.pipe(debugStream(PromptDialog.name));
  }
}
