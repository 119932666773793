import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faCreditCard, faShoppingCart } from '@fortawesome/pro-solid-svg-icons';
import { TemporaryCartService } from '../services';

@Component({
  selector: 'app-cart-checkout-summary-component',
  template: `
    <div class="row">
      <div class="col">
        <h5 class="text-end">Before Discount: USD {{ temporaryCartService.getTotalBeforeDiscount() | currency }}</h5>
        <h4 class="text-end">Total Billing Amount: USD {{ temporaryCartService.getTotal() | currency }}</h4>
        <p class="text-end"><i>* Final cost may change after tax, discounts, and shipping have been applied.</i></p>
        <hr />
        <div class="form-group text-end">
          <a routerLink="/shop" class="btn btn-outline-primary">
            <fa-icon [icon]="icons.shoppingCart"></fa-icon>
            Continue Shopping </a
          >&nbsp;
          <button class="btn btn-success" (click)="go()" [disabled]="!temporaryCartService.signalCart()?.items?.length">
            <fa-icon [icon]="icons.creditCard"></fa-icon>
            Proceed to Checkout
          </button>
        </div>
      </div>
    </div>
  `,
})
export class CartCheckoutSummaryComponent {
  public icons = { creditCard: faCreditCard, shoppingCart: faShoppingCart };

  constructor(private router: Router, public temporaryCartService: TemporaryCartService) {}

  go() {
    this.router.navigate(['/shop/checkout']);
  }
}
