import { Component } from '@angular/core';
import { faShoppingCart, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { TemporaryCartService } from '../services';

@Component({
  selector: 'app-cart-table-component',
  template: `
    <ng-container>
      <ng-container>
        <h1>{{ temporaryCartService.signalCart()?.name }}</h1>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th style="width: 1%;"></th>
                <th>Part</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>

                <th>Discount</th>
                <th>After Discount</th>
                <th style="width: 1%;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of temporaryCartService.signalCart()?.items">
                <td>
                  <img
                    height="40"
                    width="40"
                    [src]="'https://parts.minnich-mfg.com/parts/' + item.itemNumber + '.jpg?w=100'"
                    onerror="this.onerror=null;this.src='https://parts.minnich-mfg.com/parts/unknown.jpg?w=100';"
                  />
                </td>
                <td>
                  <h5 class="mb-0">
                    <!--<a href="/shop/parts/view/{{ item.itemNumber }}">{{ item.itemNumber }}</a>-->
                    {{ item.itemNumber }}
                  </h5>
                  {{ item.itemName }}
                </td>
                <td>{{ item.price | currency }}</td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="item.quantity"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="temporaryCartService.updateQuantity(item, $event)"
                    min="1"
                    max="255"
                  />
                </td>

                <td>{{ temporaryCartService.getItemTotalBeforeDiscount(item) | currency }}</td>
                <td>{{ item.discountPercentage | percent }}</td>
                <td>{{ temporaryCartService.getItemTotalAfterDiscount(item) | currency }}</td>
                <td>
                  <button class="btn btn-danger btn-sm" (click)="temporaryCartService.removeProduct(item)">
                    <fa-icon [icon]="icons.trash"></fa-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </ng-container>
  `,
})
export class CartTableComponent {
  public icons = { trash: faTrashAlt, shoppingCart: faShoppingCart };

  constructor(public readonly temporaryCartService: TemporaryCartService) {}
}
