<h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Order history</h1>
<p class="mt-2 text-sm text-gray-500">Check the status of recent orders, manage returns, and discover similar products.</p>

<div class="mx-auto mt-8">
  <div class="mx-auto space-y-8 sm:px-4 lg:px-0">
    @for (order of orders; track order) {
    <div class="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
      <h3 class="sr-only">
        Order placed on
        <time datetime="2021-07-06">Jul 6, 2021</time>
      </h3>

      <div class="flex items-center border-b border-gray-200 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6">
        <dl class="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
          <div>
            <dt class="font-medium text-gray-900">Order number</dt>
            <dd class="mt-1 text-gray-500">{{ order.DocNum }}</dd>
          </div>
          <div class="hidden sm:block">
            <dt class="font-medium text-gray-900">Date placed</dt>
            <dd class="mt-1 text-gray-500">
              <time datetime="2021-07-06">{{ order.DocDate | date : 'longDate' }}</time>
            </dd>
          </div>
          <div>
            <dt class="font-medium text-gray-900">Total amount</dt>
            <dd class="mt-1 font-medium text-gray-900">{{ order.DocTotal | currency }}</dd>
          </div>
        </dl>

        <div class="relative flex justify-end lg:hidden">
          <div class="flex items-center">
            <button
              type="button"
              class="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500"
              id="menu-0-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span class="sr-only">Options for order WU88191111</span>
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                />
              </svg>
            </button>
          </div>

          <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
          <!--            <div class="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu"-->
          <!--                 aria-orientation="vertical" aria-labelledby="menu-0-button" tabindex="-1">-->
          <!--              <div class="py-1" role="none">-->
          <!--                &lt;!&ndash; Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" &ndash;&gt;-->
          <!--                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-0-item-0">View</a>-->
          <!--                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-0-item-1">Invoice</a>-->
          <!--              </div>-->
          <!--            </div>-->
        </div>

        <!--          <div class="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">-->
        <!--            <a href="#"-->
        <!--               class="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">-->
        <!--              <span>View Order</span>-->
        <!--              <span class="sr-only">WU88191111</span>-->
        <!--            </a>-->
        <!--            <a href="#"-->
        <!--               class="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">-->
        <!--              <span>View Invoice</span>-->
        <!--              <span class="sr-only">for order WU88191111</span>-->
        <!--            </a>-->
        <!--          </div>-->
      </div>

      <p-panel header="Items" [collapsed]="true" [toggleable]="true">
        <!-- Products -->
        <h4 class="sr-only">Items</h4>
        <ul role="list" class="divide-y divide-gray-200">
          @for (line of order.DocumentLines; track line) {
          <li class="p-4 sm:p-6">
            <div class="flex items-center sm:items-start">
              <div class="h-10 w-10 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200">
                <img
                  onerror="this.onerror=null;this.src='https://parts.minnich-mfg.com/parts/unknown.jpg';"
                  src="https://parts.minnich-mfg.com/parts/{{ line.ItemCode }}.jpg"
                  class="h-full w-full object-cover object-center"
                />
              </div>
              <div class="ml-6 flex-1 text-sm">
                <div class="mb-1 font-medium text-gray-900 sm:flex sm:justify-between">
                  <h5>{{ line.ItemCode }} - {{ line.ItemDescription }}</h5>
                </div>
                <div class="flex justify-between text-gray-500">
                  <span>Quantity: {{ line.Quantity }}</span>
                  <span class="hidden md:inline">Discount: {{ line.DiscountPercent / 100 | percent }}</span>
                  <span class="hidden md:inline"
                    >Unit Price: <span class="line-through">{{ line.UnitPrice | currency }}</span> / {{ line.Price | currency }}</span
                  >
                  <span>Total: {{ line.LineTotal | currency }}</span>
                </div>
              </div>
            </div>
          </li>
          }
        </ul>
      </p-panel>
    </div>
    }
  </div>
</div>
<div class="text-center">
  <p-paginator (onPageChange)="pageChanged($event)" [first]="first" [rows]="rows" [totalRecords]="count"></p-paginator>
</div>
