import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxTableModule } from '@mm-mono/modules-client/ngx/table';
import { NgxBootstrapFormValidationModule } from '@mm-mono/ngx/bootstrap-form-validation';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import {
  BillingShippingFormComponent,
  CartCheckoutSummaryComponent,
  CartSummaryComponent,
  CartTableComponent,
  CheckoutComponent,
  SavedCartsIndexComponent,
  SavedCartViewModal,
  ShopComponent,
  ThanksComponent,
} from './components';
import { AccountHistoryComponent } from './components/account-history.component';
import { CheckoutService } from './services';
import { CHECKOUT_ROUTES, SHOP_STATES } from './states';

export * from './services';
export * from './states';
export * from './components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(SHOP_STATES),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxTableModule,
    FontAwesomeModule,
    RouterModule.forChild(CHECKOUT_ROUTES),
    NgxBootstrapFormValidationModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    PanelModule,
    PaginatorModule,
  ],
  declarations: [
    SavedCartViewModal,
    CartTableComponent,
    CartSummaryComponent,
    CartCheckoutSummaryComponent,
    ShopComponent,
    SavedCartsIndexComponent,
    AccountHistoryComponent,
    CheckoutComponent,
    ThanksComponent,
    BillingShippingFormComponent,
  ],
  providers: [CheckoutService],
  exports: [],
})
export class ModulesStoreFrontClientModule {}
