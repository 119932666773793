import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { SortDirection, SortParams } from '@mm-mono/common';
import dayjs from 'dayjs';
import { PaginatorState } from 'primeng/paginator';
import { BehaviorSubject, combineLatest, firstValueFrom, lastValueFrom, mergeMap, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { ISearchResult } from './interface/ISearchResult';
import { ISortEvent } from './interface/ISortEvent';
import { DataPaginationSubject, PaginationQuery } from './interface/PaginationQuery';

@Injectable()
export class NgxTableService<T> {
  public searchColumns: Array<string>;
  public dataSubject: BehaviorSubject<Array<T>> = new BehaviorSubject<T[]>([]);
  public searchTerm: BehaviorSubject<string> = new BehaviorSubject('');
  public total: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public pageSize: BehaviorSubject<number> = new BehaviorSubject<number>(10);
  public page: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public sortParams: BehaviorSubject<SortParams> = new BehaviorSubject<SortParams>({
    sortColumn: '',
    sortDirection: SortDirection.NULL,
  });
  public url: BehaviorSubject<string> = new BehaviorSubject('');
  public fn: BehaviorSubject<(page: number, pageSize: number, searchTerm: string, sortParams: any) => Observable<any>> = new BehaviorSubject(
    (page, pageSize, searchTerm, sortParams) => void 0
  );
  public requiresInput: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingEmitter = new EventEmitter();
  public refresh: EventEmitter<any> = new EventEmitter();
  private _data: Array<T> = [];
  private _pagination: Partial<PaginationQuery> = {};
  private updateData = new EventEmitter();
  private combineSubscription: Subscription;

  constructor(private http: HttpClient) {
    this.onInit();
    this.refresh.subscribe(() => console.log('refresh in service'));

    this.combineSubscription = combineLatest([
      this.pageSize,
      this.page,
      this.searchTerm.pipe(debounceTime(500), distinctUntilChanged()),
      this.sortParams,
      this.url,
      this.requiresInput,
      this.refresh.pipe(startWith([])),
    ])
      .pipe(debounceTime(300))
      .subscribe(async ([pageSize, page, searchTerm, sortParams, url, requiresInput]) => {
        if (requiresInput && !searchTerm) {
          return;
        }
        await this.query(pageSize, page, searchTerm, sortParams, url);
      });

    this.updateData.pipe(switchMap(() => this.search())).subscribe(({ data, total }) => {
      this.total.next(total);
      this.dataSubject.next(data);
    });
  }

  public onInit() {
    this.searchColumns = [];
    this.searchTerm.next('');
    this._data = [];
    this.dataSubject.next([]);

    this.total.next(0);
    this.pageSize.next(10);
    this.page.next(1);
  }

  public changePageSize(pageSize: number) {
    this.pageSize.next(pageSize);
  }

  public updatePaginatorState(event: PaginatorState) {
    console.log(event);
    this.page.next(event.page * event.rows);
    this.pageSize.next(event.rows);
  }

  public changePage(pageNumber: PaginatorState) {
    console.log(pageNumber);
    this.page.next(pageNumber.page);
  }

  public onSearch(event: string) {
    this.searchTerm.next(event);
  }

  public setDataAndPagination(data: DataPaginationSubject) {
    console.log(data);
    this.setData(data.data);
    this.setPagination(data.pagination);
    this.updateData.emit();
  }

  public sortBy({ column, direction }: ISortEvent) {
    this.sortParams.next({ sortColumn: column, sortDirection: direction });
  }

  public addSearchColumn(column: string) {
    this.searchColumns.push(column);
  }

  private async query(pageSize: number, page: number, searchTerm: string, sortParams: SortParams, url: string) {
    const searchQuery = [
      '?',
      [
        `page=${page || 1}`,
        `pageSize=${pageSize || 5}`,
        `searchTerm=${searchTerm || ''}`,
        `sortColumn=${sortParams.sortColumn || ''}`,
        `sortDirection=${sortParams.sortDirection || SortDirection.NULL}`,
      ].join('&'),
    ].join('');
    this.loadingEmitter.emit(true);
    let res;
    if (this.url.getValue()) {
      res = await lastValueFrom(this.http.get<DataPaginationSubject>(url + searchQuery));
    } else {
      res = await firstValueFrom(this.fn.pipe(mergeMap((d) => d(page, pageSize, searchTerm, sortParams))));
    }
    this.setDataAndPagination(res);
    this.loadingEmitter.emit(false);
  }

  private setData(value: any[] = []) {
    this._data = value;
  }

  private setPagination(value: PaginationQuery) {
    this._pagination.pageCount = value.pageCount;
    this._pagination.total = value.total;
  }

  private search(): Observable<ISearchResult<T>> {
    const sortParams = this.sortParams.getValue();
    const sortColumn = sortParams.sortColumn;
    const sortDirection = sortParams.sortDirection;
    const searchTerm = this.searchTerm.getValue();

    const data = this.sort(this._data, sortColumn, sortDirection);
    // data = data.filter(t => this.matches(t, searchTerm));

    return of({ data, total: this._pagination.total });
  }

  private compare(v1, v2): number {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  private compareDate(v1, v2): number {
    return dayjs(v1).isBefore(dayjs(v2)) ? -1 : dayjs(v1).isAfter(dayjs(v2)) ? 1 : 0;
  }

  private sort(data: Array<T>, column: string, direction: string): Array<T> {
    if (direction === SortDirection.NULL) {
      return data;
    }
    return [...data].sort((a, b) => {
      let res;
      if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(a[column])) {
        res = this.compareDate(a[column], b[column]);
      } else {
        res = this.compare(a[column], b[column]);
      }
      return direction === SortDirection.ASC ? res : -res;
    });
  }

  // private matches(data: T, term: string) {
  // 	return this.searchColumns.some((v, k) => (data[v] || '').toString().toLowerCase().includes(term.toLowerCase()));
  // }
}
