import { Component } from '@angular/core';
import { UpToDateBuildService } from '@mm-mono/ngx/app-version-checker';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'orange-bar',
  standalone: true,
  template: `
    @if (!buildIsUpToDate) {
    <div class="bg-accent-400 bg-grunge">
      <div class="mx-auto px-4 py-2   sm:px-6 lg:px-12">
        <h6 class="m-0 p-0 text-center uppercase text-white">
          A new version of the website has been released. <a (click)="reloadWebsite()" class="underline" href="javascript:void(0)">Click here</a> to update
        </h6>
      </div>
    </div>
    }
  `,
})
export class OrangeBarComponent {
  public buildIsUpToDate = true;

  constructor(private upToDateService: UpToDateBuildService, private dialogsService: NgxDialogsService) {
    this.upToDateService.buildIsUpToDateSubject.subscribe((buildIsUpToDate) => (this.buildIsUpToDate = buildIsUpToDate));
  }

  public reloadWebsite() {
    this.dialogsService
      .confirm({
        header: 'Update Available',
        message: 'A new version of the website has become available. The website will be reloaded. Do you want to reload now? Unsaved changes will be lost.',
        acceptButtonLabel: 'Yes',
        rejectButtonLabel: 'No',
      })
      .pipe(filter(Boolean))
      .subscribe(() => window.location.reload());
  }
}
