import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { AuthService } from '@appwrite/angular';
import { faArchive, faBuilding, faClose, faCog, faEllipsisVertical, faHome, faPlus, faShoppingCart, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { TemporaryCartService } from '@mm-mono/modules/store-front/client';

@Component({
  selector: 'account-index',
  template: `
    <section class="container space-y-10 px-4 py-10 lg:px-12">
      <div class="flex flex-col items-start lg:flex-row">
        <div *ngIf="showSidebar" @fadeAnimation aria-modal="true" class="relative z-50 lg:hidden" role="dialog">
          <div class="fixed inset-0 bg-gray-900/80"></div>
          <div class="fixed inset-0 flex">
            <div class="relative mr-16 flex w-full max-w-xs flex-1">
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button (click)="showSidebar = false" class="-m-2.5 p-2.5" type="button">
                  <span class="sr-only">Close sidebar</span>
                  <svg aria-hidden="true" class="h-6 w-6 text-white" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
                    <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
              </div>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                <nav class="mt-16 flex flex-1 flex-col">
                  <ul class="flex flex-1 flex-col gap-y-7" role="list">
                    <li *ngFor="let group of navItems">
                      <div class="text-xs font-semibold leading-6 text-gray-400">{{ group.name }}</div>
                      <ul class="-mx-2 space-y-1" role="list">
                        <li *ngFor="let item of group.children">
                          <a
                            [routerLink]="item.link"
                            class=" hover:text-accent-600 group flex   gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                            routerLinkActive="!bg-gray-50 !text-accent-600"
                            [routerLinkActiveOptions]="{ exact: true }"
                          >
                            <fa-icon [icon]="item.icon" size="xl"></fa-icon>
                            {{ item.name }}
                            @if (item.badge) {
                            <span
                              class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                              >{{ item?.badge() }}</span
                            >
                            }
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <!-- Static sidebar for desktop -->
        <div class="hidden lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <nav class="flex flex-1 flex-col">
              <ul class="flex flex-1 flex-col gap-y-7" role="list">
                <li *ngFor="let group of navItems">
                  <div class="text-xs font-semibold leading-6 text-gray-400">{{ group.name }}</div>
                  <ul class="-mx-2 space-y-1" role="list">
                    <li *ngFor="let item of group.children">
                      <a
                        [routerLink]="item.link"
                        class=" hover:text-accent-600 group flex   gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        routerLinkActive="!bg-gray-50 !text-accent-600"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        <fa-icon [icon]="item.icon" size="xl"></fa-icon>
                        {{ item.name }}
                        @if (item.badge) {
                        <span
                          class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                          >{{ item?.badge() }}</span
                        >

                        }
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="top-0 z-40 mb-6 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button (click)="showSidebar = true" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" type="button">
            <span class="sr-only">Open sidebar</span>
            <svg aria-hidden="true" class="h-6 w-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
              <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
          <div class="flex-1 text-sm font-semibold leading-6 text-gray-900">Menu</div>
        </div>
        <main class="w-full grow">
          <div class="px-4 sm:px-6 lg:px-8">
            <router-outlet></router-outlet>
          </div>
        </main>
      </div>
    </section>
  `,
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [style({ opacity: 0 }), animate('300ms ease', style({ opacity: 1 }))]),
      transition('* => void', [animate('300ms ease', style({ opacity: 0 }))]),
    ]),
  ],
})
export class PortalIndexComponent {
  public navItems = [
    // {
    //   name: '', children: [
    //     { name: 'Home', icon: faHome, link: ['/portal/account/home',] },
    //   ]
    // },
    {
      name: 'Shop',
      children: [
        { name: 'My Cart', badge: this.temporaryCartService.getItemsTotal, icon: faShoppingCart, link: ['/portal/shop/carts'] },
        { name: 'Parts', badge: null, icon: faCog, link: ['/portal/shop/parts'] },
        { name: 'Order History', badge: null, icon: faArchive, link: ['/portal/shop/history'] },
      ],
    },
    {
      name: 'Your Account',
      children: [
        { name: 'Profile', badge: null, icon: faUser, link: ['/portal/account/profile'] },
        { name: 'Company', badge: null, icon: faBuilding, link: ['/portal/account/company'] },
      ],
    },
  ];
  icons = { faShoppingCart, faEllipsisVertical, faClose, faPlus, faCircleArrowRight };
  title = 'mm-inventory';
  showSidebar = false;
  protected readonly transition = transition;
  protected readonly faHome = faHome;

  constructor(public temporaryCartService: TemporaryCartService, public authService: AuthService) {}
}
