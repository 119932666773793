import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface ConfirmModalOptions {
  message?: string;
  header?: string;
  acceptButtonLabel?: string;
  rejectButtonLabel?: string;
  acceptButtonValue?: any;
  rejectButtonValue?: any;
}

@Component({
  selector: 'confirm-modal-component',
  template: `
    <div class="mt-2">
      <p class="text-gray-500">{{ message }}</p>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        (click)="accept()"
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
      >
        {{ acceptButtonLabel }}
      </button>
      <button
        (click)="reject()"
        type="button"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      >
        {{ rejectButtonLabel }}
      </button>
    </div>
  `,
})
export class ConfirmDialog implements OnInit {
  @Input() message: string;
  @Input() title: string;
  @Input() acceptButtonLabel = 'Yes';
  @Input() rejectButtonLabel = 'No';
  @Input() acceptButtonValue = true;
  @Input() rejectButtonValue = false;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    console.log(`Opened ${this.constructor.name} with`, this.config);
    Object.keys(this.config?.data).forEach((k) => (this[k] = this.config?.data?.[k] ?? this[k]));
  }

  public accept() {
    this.ref.close(this.acceptButtonValue);
  }

  public reject() {
    this.ref.close(this.rejectButtonValue);
  }
}
