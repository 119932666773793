import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppwriteException } from '@appwrite/angular';
import { LoginDto } from '@mm-mono/data/appwrite';
import { AuthService } from '@appwrite/angular';
import { MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-login',
  template: `
    <div class="fixed inset-0 bottom-0 left-0 right-0 top-0 z-[-1] block bg-[url('/assets/views/auth/cleveland-dust-collector.jpg')] bg-cover opacity-30"></div>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <a [routerLink]="['/']" class="flex items-center">
          <minnich-logo [showSubtext]="true" [style.width]="200" class="mx-auto  w-auto"></minnich-logo>
        </a>
      </div>

      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <h2 class="mb-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>

          <form (submit)="submit()" [formGroup]="formData" class="space-y-6">
            <div class="form-group">
              <label class="mb-2 block leading-6 text-gray-900" for="email">Email</label>
              <input formControlName="email" id="email" pInputText type="text" />
            </div>

            <div class="form-group">
              <label class="mb-2 block leading-6 text-gray-900" for="email">Password</label>
              <input autocomplete="current-password" formControlName="password" id="password" pInputText type="password" />
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <p-checkbox
                  [binary]="true"
                  [label]="'Remember me'"
                  formControlName="rememberMe"
                  inputId="remember-me"
                  styleClass="focus:ring-accent-600"
                ></p-checkbox>
              </div>

              <div class="text-sm leading-6">
                <a class="text-accent-600 hover:text-accent-500 font-semibold" routerLink="/forgot">Forgot password?</a>
              </div>
            </div>

            <div>
              <button
                class="bg-accent-600 hover:bg-accent-500 focus-visible:outline-accent-600 flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                type="submit"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./auth-state.styles.scss'],
})
export class AuthLoginComponent {
  public formData: FormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private authService: AuthService, private messageService: MessageService) {}

  ngOnInit() {
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    const rememberMeUsername = localStorage.getItem('rememberMeUsername');
    this.formData = this.formBuilder.group({
      email: [rememberMeUsername, [Validators.required, Validators.email]],
      rememberMe: [rememberMe, []],
      password: ['', [Validators.required]],
    });
  }

  public async submit() {
    if (this.formData?.valid) {
      try {
        const formData = this.formData.value;
        localStorage.setItem('rememberMe', formData.rememberMe.toString());
        localStorage.setItem('rememberMeUsername', formData.rememberMe ? formData.email : '');
        await firstValueFrom(this.authService.login(formData as LoginDto));
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 1500);
      } catch (e) {
        if (e instanceof AppwriteException) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: e.message });
        } else if (e instanceof HttpErrorResponse) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: e.error.message });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An unknown error has occurred.' });
        }
        console.error(e);
      }
    }
  }
}
