import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, mustMatch } from '@appwrite/angular';

@Component({
  selector: 'mm-auth-register-form-component',
  template: `
    <form (submit)="submit()" [formGroup]="formData" disabled>
      <fieldset disabled>
        <input-label formControlName="name" label="Name" autocomplete="name"></input-label>
        <input-label formControlName="email" label="Email" autocomplete="email"></input-label>
        <div class="row">
          <div class="col">
            <input-label formControlName="password" label="Password" type="password" autocomplete="new-password"></input-label>
          </div>
          <div class="col">
            <input-label formControlName="passwordConfirm" label="Password Confirm" type="password" autocomplete="new-password"></input-label>
          </div>
        </div>
        <div class="d-grid">
          <button type="submit" class="btn btn-primary btn-lg">Sign Up</button>
        </div>
      </fieldset>
    </form>
  `,
})
export class RegisterFormComponent implements OnInit {
  public formData: UntypedFormGroup;
  @Output() private register = new EventEmitter<UntypedFormGroup>();

  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService) {}

  ngOnInit() {
    this.formData = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirm: ['', [Validators.required, Validators.minLength(8)]],
        email: ['', [Validators.required, Validators.email]],
        name: ['', [Validators.required]],
      },
      {
        validators: [mustMatch('password', 'passwordConfirm')],
      }
    );
  }

  public async submit() {
    throw new Error('Registrations are currently disabled');
    if (this.formData.valid) {
      this.register.emit(this.formData);
    }
  }
}
