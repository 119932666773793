import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildDetails } from './ngx-app-version-checker.module';
import { BuildDetailsService } from './build-details.service';

@Injectable()
export class BuildDetailsHttpService {
  httpClient: HttpClient;
  handler: HttpBackend;

  constructor(private http: HttpClient, handler: HttpBackend, private buildDetailsService: BuildDetailsService) {
    this.httpClient = http;
    this.handler = handler;
  }

  fetchBuildDetails(): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    };

    this.httpClient = new HttpClient(this.handler);
    return new Promise((resolve) => {
      this.httpClient
        .get<BuildDetails>('assets/build-details.json', httpOptions)
        .toPromise()
        .then((response) => {
          this.buildDetailsService.buildDetails.buildDate = response.buildDate;
          resolve();
        });
    });
  }
}
