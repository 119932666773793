import { Component } from '@angular/core';

@Component({
  selector: 'green-bar',
  imports: [],
  standalone: true,
  template: `
    <div class="text-neutral-800">
      <div class="bg-accent-500 bg-grunge">
        <div class="mx-auto px-4 py-2   sm:px-6 lg:px-12">
          <h6 class="m-0 p-0 text-center uppercase text-white">our people make the difference</h6>
        </div>
      </div>
    </div>
  `,
})
export class GreenBarComponent {}
