<main class="gap-6 lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
  <h1 class="sr-only">Checkout</h1>

  <!-- Mobile order summary -->
  <section aria-labelledby="order-heading" class="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden">
    <div class="mx-auto max-w-lg">
      <div class="flex items-center justify-between">
        <h2 class="text-lg font-medium text-gray-900" id="order-heading">Your Order</h2>
        <button aria-controls="disclosure-1" aria-expanded="false" class="text-accent-600 hover:text-accent-500 font-medium" type="button">
          <!-- Only display for open option. -->
          <span>Hide full summary</span>
          <!-- Don't display for open option. -->
          <span>Show full summary</span>
        </button>
      </div>

      <div id="disclosure-1">
        <ul class="divide-y divide-gray-200 border-b border-gray-200" role="list">
          <li *ngFor="let item of shopCartService.signalCart()?.items" class="flex space-x-6 py-6">
            <img
              [src]="'https://parts.minnich-mfg.com/parts/' + item.itemNumber + '.jpg'"
              class="h-16 w-16 flex-none rounded-md bg-gray-200 object-cover object-center"
              onerror="this.onerror=null;this.src='https://parts.minnich-mfg.com/parts/unknown.jpg';"
            />
            <div class="flex flex-col justify-between space-y-4">
              <div class="space-y-1 text-sm font-medium">
                <h3 class="text-gray-900">{{ item.itemNumber }}</h3>
                <p class="text-gray-900">{{ item.itemName }}</p>
                <p class="text-gray-500">
                  @if (item.discountPercentage) {
                  <span class="">
                    <span class="line-through">{{ item.price * item.quantity | currency }}</span> / {{ item.discountPrice * item.quantity | currency }}</span
                  >
                  <small class="ml-1 text-neutral-500">{{ item.discountPercentage | percent }}</small>
                  } @else {
                  <span class="">{{ item.price | currency }}</span>
                  }
                </p>
              </div>
              <div class="flex space-x-4">
                <button class="text-accent-600 hover:text-accent-500 text-sm font-medium" type="button">Edit</button>
                <div class="flex border-l border-gray-300 pl-4">
                  <button class="text-accent-600 hover:text-accent-500 text-sm font-medium" type="button">Remove</button>
                </div>
              </div>
            </div>
          </li>

          <!-- More products... -->
        </ul>

        <dl class="mt-10 space-y-6 text-sm font-medium text-gray-500">
          <div class="flex justify-between">
            <dt class="text-sm text-gray-600">Total before discount</dt>
            <dd class="text-sm font-medium text-gray-900">{{ shopCartService.getTotalBeforeDiscount() | currency }}</dd>
          </div>

          <div class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
            <dt class="text-base font-medium text-gray-900">Total <sup>†</sup></dt>
            <dd class="text-base font-medium text-gray-900">{{ shopCartService.getTotal() | currency }}</dd>
          </div>
        </dl>
        <div class="mt-6">
          <small><sup>†</sup> Final cost may change after tax, discounts, and shipping have been applied in our system.</small>
        </div>
      </div>

      <p class="mt-6 flex items-center justify-between border-t border-gray-200 pt-6 text-sm font-medium text-gray-900">
        <span class="text-base">Total</span>
        <span class="text-base">$141.92</span>
      </p>
    </div>
  </section>

  <!-- Order summary -->
  <section aria-labelledby="summary-heading" class="hidden w-full max-w-sm flex-col bg-gray-50 lg:flex">
    <h2 class="sr-only" id="summary-heading">Order summary</h2>

    <ul class="divide-y divide-gray-200 overflow-y-auto px-6" role="list">
      <li *ngFor="let item of shopCartService.signalCart()?.items" class="flex space-x-6 py-6">
        <img
          [src]="'https://parts.minnich-mfg.com/parts/' + item.itemNumber + '.jpg'"
          class="h-16 w-16 flex-none rounded-md bg-gray-200 object-cover object-center"
          onerror="this.onerror=null;this.src='https://parts.minnich-mfg.com/parts/unknown.jpg';"
        />
        <div class="flex flex-col justify-between space-y-4">
          <div class="space-y-1 text-sm font-medium">
            <h3 class="text-gray-900">{{ item.itemNumber }}</h3>
            <p class="text-gray-900">{{ item.itemName }}</p>
            <p class="text-gray-500">
              <span class="mr-2">({{ item.quantity }})</span>
              @if (item.discountPercentage) {
              <span class="hidden md:inline">
                <span class="line-through">{{ item.price * item.quantity | currency }}</span> / {{ item.discountPrice * item.quantity | currency }}</span
              >
              <small class="ml-1 text-neutral-500">{{ item.discountPercentage | percent }}</small>
              } @else {
              <span class="hidden md:inline">{{ item.price | currency }}</span>
              }
            </p>
          </div>
          <div class="flex space-x-4">
            <button class="text-accent-600 hover:text-accent-500 text-sm font-medium" type="button" (click)="edit(item)">Edit</button>
            <div class="flex border-l border-gray-300 pl-4">
              <button class="text-accent-600 hover:text-accent-500 text-sm font-medium" type="button" (click)="shopCartService.removeProduct(item)">
                Remove
              </button>
            </div>
          </div>
        </div>
      </li>

      <!-- More products... -->
    </ul>

    <div class="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6">
      <dl class="mt-10 space-y-6 text-sm font-medium text-gray-500">
        <div class="flex justify-between">
          <dt class="text-sm text-gray-600">Total before discount</dt>
          <dd class="text-sm font-medium text-gray-900">{{ shopCartService.getTotalBeforeDiscount() | currency }}</dd>
        </div>

        <div class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
          <dt class="text-base font-medium text-gray-900">Total <sup>†</sup></dt>
          <dd class="text-base font-medium text-gray-900">{{ shopCartService.getTotal() | currency }}</dd>
        </div>
      </dl>
      <div class="mt-6">
        <small><sup>†</sup> Final cost may change after tax, discounts, and shipping have been applied in our system.</small>
      </div>
    </div>
  </section>

  <!-- Checkout form -->
  <section aria-labelledby="payment-heading" class="flex-auto overflow-y-auto pb-16 lg:pb-24">
    <div class="mx-auto">
      <form #checkoutFormTag action="" class="mt-6" form name="checkout">
        <fieldset [disabled]="loading" class="space-y-6">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Checkout</h2>
          <hr />
          <h3 class="block text-lg">1. Bill To</h3>
          <billing-shipping-form
            #billingForm
            class="block"
            (data)="assign(checkoutForm.billingForm, $event)"
            [addresses]="$addresses"
            [formType]="'B'"
          ></billing-shipping-form>
          <hr />
          <h3 class="block text-lg">2. Ship To</h3>
          <billing-shipping-form
            class="block"
            #shippingForm
            (data)="assign(checkoutForm.shippingForm, $event)"
            [addresses]="$addresses"
            [formType]="'S'"
          ></billing-shipping-form>
          <hr />

          <h3 class="my-2 text-lg">3. Shipping Method</h3>
          <div class="form-group">
            <label class="mb-1 block text-sm font-medium text-gray-700">Shipping</label>
            <p-dropdown
              [(ngModel)]="checkoutForm.shippingMethod.method"
              [options]="$shipping | async"
              styleClass="w-full"
              [virtualScroll]="true"
              [virtualScrollItemSize]="38"
              name="shippingMethod.method"
              optionLabel="TrnspName"
              placeholder="Select Shipping Carrier"
            ></p-dropdown>
          </div>

          <div class="form-group sm:col-span-2">
            <label class="mb-1 block text-sm font-medium text-gray-700" for="shippingMethod.freight">Freight</label>
            <p-dropdown
              [(ngModel)]="checkoutForm.shippingMethod.freight"
              [options]="$freight | async"
              name="shippingMethod.freight"
              [virtualScroll]="true"
              [virtualScrollItemSize]="38"
              styleClass="w-full"
              optionLabel="FldValue"
              placeholder="Select Freight"
            ></p-dropdown>
          </div>
          <div class="form-group sm:col-span-2">
            <label class="mb-1 block text-sm font-medium text-gray-700" for="shippingMethod.account">Carrier Account Number</label>
            <input [(ngModel)]="checkoutForm.shippingMethod.account" id="shippingMethod.account" name="shippingMethod.account" pInputText type="text" />
          </div>

          <hr />
          <h3 class="my-2 text-lg">4. PO Number</h3>

          <div class="form-group sm:col-span-2">
            <label class="mb-1 block text-sm font-medium text-gray-700" for="poNumber">PO Number</label>
            <input [(ngModel)]="checkoutForm.poNumber" id="poNumber" name="poNumber" pInputText type="text" />
          </div>
        </fieldset>
        <button
          (click)="checkout()"
          [disabled]="loading || !billingForm.formData.valid || !shippingForm.formData.valid"
          class="bg-accent-600 hover:bg-accent-700 focus:ring-accent-500 mt-6 w-full rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
          type="submit"
        >
          Submit Order
        </button>
      </form>
    </div>
  </section>
</main>
