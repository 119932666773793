import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxBootstrapInputModule } from '@mm-mono/modules-client/ngx/bootstrap-input';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AlertDialog, ConfirmDialog, PromptDialog, UploadDialog } from './dialogs';
import { NgxDialogDirective } from './ngx-dialog.directive';
import { NgxDialogsService } from './ngx-dialogs.service';

export * from './ngx-dialogs.service';
export * from './ngx-dialog.directive';
export * from './dialogs';

@NgModule({
  imports: [CommonModule, FormsModule, NgxBootstrapInputModule, DynamicDialogModule],
  providers: [NgxDialogsService, DialogService],
  declarations: [ConfirmDialog, AlertDialog, PromptDialog, NgxDialogDirective, UploadDialog],
  exports: [NgxDialogDirective],
})
export class NgxDialogsModule {}
