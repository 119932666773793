import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'home-carousel',
  template: `
    <section>
      <p-carousel
        [autoplayInterval]="5000"
        [circular]="true"
        [numScroll]="1"
        [numVisible]="1"
        [showIndicators]="false"
        [value]="slides"
        class="products-carousel"
      >
        <ng-template let-slide pTemplate="item">
          <div class="relative">
            <div class="absolute h-full w-full bg-black/50"></div>
            <appwrite-image
              [gravity]="'bottom-right'"
              [width]="1200"
              [src]="slide.background"
              class="block aspect-[4/3] max-h-[500px] min-w-full max-w-full sm:aspect-[16/9] md:aspect-[21/9]"
              imgClasses="w-full h-full"
              objectFit="cover"
            >
            </appwrite-image>
            <div class="absolute bottom-0 w-full p-4 sm:p-6">
              <h1 class="text-end text-2xl text-white">{{ slide.h1 }}</h1>
              <p class="text-end text-sm text-white sm:text-lg">{{ slide.p }}</p>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </section>
  `,
})
export class CarouselComponent implements OnInit {
  public slides = [
    {
      background: '62151b6af03b9',
      p: "Minnich Manufacturing is leading the way in concrete consolidation for today's flowable mixes.  “Slow it Down, Hit it harder” with a control speed vibrator.",
      h1: 'Control Speed Vibrator',
      link: () => this.router.navigate(['/products', 'vibrators', 'flex-shaft', 'csv']),
    },
    {
      background: '62151b60daa6f',
      p: 'Minnich Manufacturing, the author of hydraulic paving vibrators, has been manufacturing reliable and powerful vibrators for nearly half a century.',
      h1: 'CONCRETE VIBRATORS',
      link: () => this.router.navigate(['/products', 'vibrators', 'paving']),
    },
    {
      background: '62151b60aa7bb',
      p: 'Minnich\u2019s reliable dowel drills are the fastest hammering concrete drills and set the standard for success for horizontal, vertical, and stitch dowel pin drilling.',
      h1: 'Concrete Dowel Pin Drills',
      link: () => this.router.navigate(['/products', 'drills']),
    },
    {
      background: '62151b60f1f06',
      p: 'Minnich Manufacturing is the home of the Stinger electric flex shaft concrete vibrator.',
      h1: 'FLEX SHAFT CONCRETE VIBRATORS',
      link: () => this.router.navigate(['/products', 'vibrators', 'flex-shaft']),
    },
    {
      background: '62151b60c7444',
      p: 'Minnich\u2019s Auto Vibe, Monitors and controls the VPM\u2019s (vibrations per minute) of hydraulic paving vibrators while paving. Minnich\u2019s vibrator sensors can be installed, and capable of monitoring, any make or model of hydraulic paving vibrators.',
      h1: 'Vibrator Monitoring Systems',
      link: () => this.router.navigate(['/products', 'vibrators', 'vibrator-monitoring']),
    },
  ];

  constructor(private router: Router) {}

  ngOnInit() {}
}
