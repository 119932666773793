import { Component, Input } from '@angular/core';
import { ModulesAppwriteModule } from '@mm-mono/modules/appwrite/client';

@Component({
  standalone: true,
  selector: 'product-feature',
  template: `
    <div class="mb-8 flex rounded-lg border border-solid border-neutral-300">
      <div class="mr-4 flex flex-shrink-0">
        <appwrite-image
          [src]="image"
          [width]="400"
          class="aspect-[4/3] w-full rounded-t-lg md:rounded-bl-lg md:rounded-tl-lg md:rounded-tr-none"
          imgClasses="h-full"
          objectFit="cover"
        ></appwrite-image>
      </div>
      <div class="flex flex-col justify-center p-10">
        <h4 class="text-xl font-bold">{{ title }}</h4>
        <p class="mt-1 leading-8">
          {{ description }}
        </p>
      </div>
    </div>
  `,
  imports: [ModulesAppwriteModule],
})
export class ProductFeatureComponent {
  @Input() public image: string;
  @Input() public title: string;
  @Input() public description: string;
}
