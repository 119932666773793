import { Injectable } from '@angular/core';
import { AppwriteDatabasesService } from '@appwrite/angular';
import { Query } from '@appwrite/common';
import { AwCollections, AwDatabases } from '@mm-mono/data/appwrite';
import { CategoriesDto, CategoryDto, ProductDto, ProductsDto } from '@mm-mono/data/dto';
import { from, Observable, shareReplay } from 'rxjs';
import { map, mergeMap, share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductsService {
  private categories: Observable<CategoriesDto>;
  private products: Observable<ProductsDto>;

  constructor(private appwriteDocumentsService: AppwriteDatabasesService) {}

  getProductRootCategories() {
    return this.getProductCategories().pipe(map((categories) => categories.filter((category) => !category.category)));
  }

  getSelectedProductRootCategory(root) {
    return this.getProductRootCategories().pipe(map((categories) => categories.find((category) => category.url === root)));
  }

  getProductGradeCategories(root) {
    return this.getProductCategories().pipe(
      map((categories) => {
        const selectedRoot = categories.find((category) => category.url === root);
        return categories.filter((category) => category.category === selectedRoot.$id);
      })
    );
  }

  getSelectedProductGradeCategory(root, grade) {
    return this.getProductGradeCategories(root).pipe(map((categories) => categories.find((category) => category.url === grade)));
  }

  getProductProducts(root, grade) {
    return this.getProductCategories().pipe(
      mergeMap((categories) => {
        const selectedRoot = categories.find((category) => category.url === root);
        const selectedGrade = categories.find((category) => category.url === grade && category.category === selectedRoot.$id);
        return this.getProducts().pipe(
          // @ts-ignore
          map((products) => products.filter((product) => (product.category.$id || product.category) === selectedGrade.$id))
        );
      })
    );
  }

  getProduct(root, grade, product) {
    return this.getProductProducts(root, grade).pipe(map((products) => products.find((prod) => prod.url === product)));
  }

  getProductCategories(): Observable<CategoriesDto> {
    if (!this.categories) {
      this.categories = from(
        this.appwriteDocumentsService.listDocuments<CategoryDto>(AwDatabases.default, AwCollections.category, [
          Query.equal('categoryType', 'product'),
          Query.limit(100),
          Query.orderAsc('name'),
        ])
      ).pipe(
        map((d) => d.documents),
        shareReplay()
        // map((categories) => categories.filter((category) => !category.category))
      );
    }
    return this.categories;
  }

  getProducts(): Observable<ProductsDto> {
    if (!this.products) {
      this.products = from(
        this.appwriteDocumentsService.listDocuments<ProductDto>(AwDatabases.default, AwCollections.product, [Query.limit(100), Query.orderAsc('name')])
      ).pipe(
        map((d) => d.documents),
        shareReplay()
      );
    }
    return this.products;
  }
}
