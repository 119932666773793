import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppwriteDatabasesService, AppwriteFunctionsService } from '@appwrite/angular';
import { ID } from '@appwrite/common';
import { SQLCommands } from '@mm-mono/cloud/sql';
import { AwCollections, AwDatabases, AwFunctions } from '@mm-mono/data/appwrite';
import { OrderDto } from '@mm-mono/data/dto';
import { CRD1, OSHP, UFD1 } from '@sap/common';
import { CheckoutFormClass } from '@mm-mono/modules/store-front/common';
import { rxJsonParse } from '@mm-mono/utilities';
import { from, Observable, pluck } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CheckoutService {
  constructor(public http: HttpClient, private documentsService: AppwriteDatabasesService, private functionsService: AppwriteFunctionsService) {}

  getFreight(): Observable<UFD1[]> {
    return from(this.functionsService.createExecution(AwFunctions.msSql, JSON.stringify({ command: SQLCommands.getFreight }))).pipe(
      rxJsonParse('responseBody'),
      pluck('responseBody', 'data'),
      shareReplay()
    );
  }

  getShipping(): Observable<OSHP[]> {
    return from(this.functionsService.createExecution(AwFunctions.msSql, JSON.stringify({ command: SQLCommands.getShipping }))).pipe(
      rxJsonParse('responseBody'),
      pluck('responseBody', 'data'),
      shareReplay()
    );
  }

  getAddresses(): Observable<CRD1[]> {
    return from(this.functionsService.createExecution(AwFunctions.msSql, JSON.stringify({ command: SQLCommands.getAddresses }))).pipe(
      rxJsonParse('responseBody'),
      pluck('responseBody', 'data'),
      shareReplay()
    );
  }
}
