import { Injectable } from '@angular/core';
import { AppwriteDatabasesService, AuthService } from '@appwrite/angular';
import { DocumentList, ID, Query, User } from '@appwrite/common';
import { AwCollections, AwDatabases } from '@mm-mono/data/appwrite';
import { ShopCartDto, ShopProductDto, UserCartDto } from '@mm-mono/data/dto';
import { SingletonService } from '@mm-mono/modules-client/common';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import { debugStream, rxJsonParse, rxJsonStringify } from '@mm-mono/utilities';
import { from, Observable, of, switchMap } from 'rxjs';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SavedCartService extends SingletonService {
  public savedCarts: ShopCartDto[];

  private sessionData: User;

  constructor(private authService: AuthService, private documentsService: AppwriteDatabasesService, private dialogService: NgxDialogsService) {
    super(SavedCartService);
    this.authService.$userData
      .pipe(
        debugStream(),
        tap(({ account }) => (this.sessionData = account)),
        switchMap(() => this.getCarts())
      )
      .subscribe();
  }

  public removeProduct(cart: ShopCartDto, item: ShopProductDto) {
    return this.dialogService
      .confirm({
        header: 'Confirm Remove',
        message: `Are you sure you want to remove "${item.itemName}" from your cart?`,
        acceptButtonLabel: 'Yes',
        rejectButtonLabel: 'No',
      })
      .pipe(
        filter(Boolean),
        withLatestFrom(of(cart)),
        map(([res, { ...cart }]) => ({ items: cart.items.filter((p) => p.itemNumber !== item.itemNumber) })),
        mergeMap((items) => this.updateCart(cart, items))
      );
  }

  public emptyCart(cart) {
    from(
      this.dialogService.confirm({
        header: 'Confirm Remove',
        message: `Are you sure you want to empty this cart?`,
        acceptButtonLabel: 'Yes',
        rejectButtonLabel: 'No',
      })
    )
      .pipe(
        filter(Boolean),
        mergeMap(() => this.updateCart(cart, { items: [] }))
      )
      .subscribe();
  }

  public getCarts(): Observable<ShopCartDto[]> {
    return from(this.documentsService.listDocuments<ShopCartDto>(AwDatabases.default, AwCollections.userCart, [Query.equal('cartType', 'LIST')])).pipe(
      map((r: DocumentList<ShopCartDto>) => r.documents),
      debugStream('TemporaryCartService'),
      rxJsonParse('items'),
      map((cart) => cart.map((cart) => new ShopCartDto(cart))),
      tap((d) => (this.savedCarts = d))
    );
  }

  public createCart(shopCartDto: ShopCartDto) {
    return of(shopCartDto).pipe(
      rxJsonStringify('items'),
      mergeMap((cartDataStringified: UserCartDto) =>
        this.documentsService.createDocument<UserCartDto>(AwDatabases.default, AwCollections.userCart, ID.unique(), cartDataStringified)
      ),
      mergeMap(() => this.getCarts())
    );
  }

  public getTotalBeforeDiscount(cart: ShopCartDto) {
    return cart?.items.reduce((a, b) => a + parseFloat((b.quantity * b.price).toFixed(2)), 0);
  }

  public getTotal(cart: ShopCartDto) {
    return cart?.items.reduce((a, b) => a + parseFloat((b.quantity * b.discountPrice).toFixed(2)), 0);
  }

  public getItemsTotal(cart: ShopCartDto) {
    return cart?.items.reduce((sum, { quantity }) => sum + quantity, 0);
  }

  public getItemTotalBeforeDiscount(item: ShopProductDto) {
    return parseFloat((item.quantity * item.price).toFixed(2));
  }

  public getItemTotalAfterDiscount(item: ShopProductDto) {
    return parseFloat((item.quantity * item.discountPrice).toFixed(2));
  }

  public updateCart(cart: ShopCartDto, shopCartDto: Partial<ShopCartDto>) {
    return of(shopCartDto).pipe(
      rxJsonStringify('items'),
      mergeMap((updateData: UserCartDto) =>
        this.documentsService.updateDocument<UserCartDto>(AwDatabases.default, AwCollections.userCart, cart.$id, updateData)
      ),
      mergeMap(() => this.getCarts())
    );
  }

  public deleteCart(cart: ShopCartDto): Observable<ShopCartDto[]> {
    return from(this.documentsService.deleteDocument(AwDatabases.default, AwCollections.userCart, cart.$id)).pipe(mergeMap(() => this.getCarts()));
  }

  public updateQuantity(cart: ShopCartDto, product: ShopProductDto, quantity: number) {
    const clonedCart = { ...cart };
    const newItems = {
      items: clonedCart.items.map((prod) => {
        if (prod.itemNumber === product.itemNumber) {
          prod.quantity = quantity;
        }
        return prod;
      }),
    };
    return this.updateCart(cart, newItems);
  }
}
