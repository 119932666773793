import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CRD1 } from '@sap/common';
import { Country, ICountry, State } from 'country-state-city';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CheckoutService } from '../services';

@Component({
  selector: 'billing-shipping-form',
  template: `
    <form #form [formGroup]="formData">
      <fieldset>
        <div class="form-group ">
          <label class="mb-1 mb-1 block text-sm font-medium text-gray-700" for="{{ formName }}.{{ formCode }}">Saved Address</label>
          <p-dropdown
            (onChange)="addressChanged()"
            [options]="$addresses | async"
            [showClear]="true"
            formControlName="Address"
            id="{{ formName }}.{{ formCode }}"
            name="{{ formName }}.{{ formCode }}"
            optionLabel="Address"
            [virtualScroll]="true"
            [virtualScrollItemSize]="38"
            optionValue="Address"
            placeholder="Select Saved Address"
            styleClass="w-full"
          ></p-dropdown>
        </div>

        <p *ngIf="f('Address').value" class="help-block">
          <i>Since you chose a saved address, you may not change the information below.</i>
        </p>

        <h4 class="my-4 text-center">or</h4>

        <fieldset [disabled]="f('Address').value" class="space-y-6">
          <div class="form-group ">
            <label class="mb-1 block text-sm font-medium text-gray-700" for="{{ formName }}.Block">Attention eg. John Doe</label>
            <input formControlName="Block" id="{{ formName }}.Block" name="{{ formName }}.Block" pInputText type="text" />
          </div>

          <div class="grid grid-cols-3 gap-6">
            <div class="form-group col-span-2">
              <label class="mb-1 block text-sm font-medium text-gray-700" for="{{ formName }}.Street">Street</label>
              <input formControlName="Street" id="{{ formName }}.Street" name="{{ formName }}.Street" pInputText type="text" />
            </div>
            <div class="form-group ">
              <label class="mb-1 block text-sm font-medium text-gray-700" for="{{ formName }}.Building">Apt, Suite, Building, etc.</label>
              <input formControlName="Building" id="{{ formName }}.Building" name="{{ formName }}.Building" pInputText type="text" />
            </div>
          </div>
          <div class="grid grid-cols-2 gap-6">
            <div class="form-group">
              <label class="mb-1 block text-sm  font-medium text-gray-700">Country</label>
              <p-dropdown
                (onChange)="change($event)"
                [virtualScroll]="true"
                [virtualScrollItemSize]="38"
                [options]="countries"
                formControlName="Country"
                optionLabel="name"
                optionValue="isoCode"
                placeholder="Select Country"
                styleClass="w-full"
              ></p-dropdown>
            </div>
            <div class="form-group">
              <label class=" mb-1 block text-sm font-medium text-gray-700">State</label>
              <p-dropdown
                [options]="states"
                [virtualScroll]="true"
                [virtualScrollItemSize]="38"
                formControlName="State"
                optionLabel="name"
                optionValue="isoCode"
                placeholder="Select State"
                styleClass="w-full"
              ></p-dropdown>
            </div>
            <div class="form-group ">
              <label class="mb-1 block text-sm font-medium text-gray-700" for="{{ formName }}.City">City</label>
              <input formControlName="City" id="{{ formName }}.City" name="{{ formName }}.City" pInputText type="text" />
            </div>
            <div class="form-group">
              <label class=" mb-1 block text-sm font-medium text-gray-700" for="{{ formName }}.ZipCode">Zip Code</label>
              <input formControlName="ZipCode" id="{{ formName }}.ZipCode" name="{{ formName }}.ZipCode" pInputText type="text" />
            </div>
          </div>

          <div class="form-group ">
            <label class="mb-1 block text-sm font-medium text-gray-700" for="{{ formName }}.U_Phone">Phone Number</label>
            <input formControlName="U_Phone" id="{{ formName }}.U_Phone" name="{{ formName }}.U_Phone" pInputText type="text" />
          </div>
        </fieldset>
      </fieldset>
    </form>
  `,
})
export class BillingShippingFormComponent implements OnInit, OnDestroy {
  @Output() public data = new EventEmitter();
  public formTypeChar: string;
  @Input() public formType: 'B' | 'S';
  public formName: string;
  public formCode: string;

  public formData: UntypedFormGroup;

  @Input() public addresses: Observable<CRD1[]>;
  public $addresses: Observable<CRD1[]>;
  // data
  public countries: ICountry[] = [];
  public states: any = [];
  private subscription: Subscription = new Subscription();

  constructor(private checkoutService: CheckoutService, private formBuilder: UntypedFormBuilder) {}

  f(control: string) {
    return this.formData.get(control);
  }

  change(event) {
    console.log(event);
    this.states = State.getStatesOfCountry(event?.value || '0');
  }

  ngOnInit() {
    this.$addresses = this.addresses.pipe(map((addresses) => addresses.filter((address) => address.AdresType === this.formTypeChar)));

    this.formData = this.formBuilder.group({
      Address: [null, []],
      AdresType: [this.formType, []],
      BillToCode: [null, []],
      ShipToCode: [null, []],
      Block: [null, []],
      Street: [null, [Validators.required]],
      Building: [null, []],
      City: [null, [Validators.required]],
      Country: [null, [Validators.required]],
      State: [null, [Validators.required]],
      ZipCode: [null, [Validators.required]],
      U_Phone: [null, []],
    });

    if (this.formType === 'B') {
      this.formTypeChar = 'B';
      this.formName = 'billingForm';
      this.formCode = 'BillToCode';
    } else {
      this.formTypeChar = 'S';
      this.formName = 'shippingForm';
      this.formCode = 'ShipToCode';
    }

    this.countries = Country.getAllCountries();

    this.formData.valueChanges.subscribe((values) => this.data.emit(values));
  }

  addressChanged() {
    try {
      // reset country and state disable state because of fieldset issue
      const val = this.formData.get('Address');
      console.log(val);
      if (val && !val.value) {
        ['Country', 'State'].forEach((i) => this.formData.get(i)?.enable());
        this.formData.get(this.formCode)?.patchValue('');
        return;
      }
      this.$addresses
        .pipe(
          take(1),
          map((addresses) => addresses.find((address) => address.Address === this.formData.get('Address').value)),
          filter((address) => Boolean(address))
        )
        .subscribe((addressData) => {
          const country: ICountry = this.countries.find((item: ICountry) => item.isoCode === addressData.Country);

          Object.keys(this.formData.controls).forEach((key) => {
            const control = this.formData.get(key);
            control.patchValue(addressData[key]);
            control.markAsDirty();
            control.markAsTouched();
            if (['Country', 'State'].find((i) => i === key)) {
              control.disable();
            }
          });
          this.formData.get(this.formCode).patchValue(addressData.Address);

          this.states = State.getStatesOfCountry(country.isoCode);
        });
    } catch (e) {
      console.error(e);
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
