import { Component, Input } from '@angular/core';

@Component({
  selector: 'minnich-logo',
  standalone: true,
  imports: [],
  template: `
    <svg [attr.width]="width" [style.mx-auto]="center" data-name="Layer 1" id="Layer_1" viewBox="0 0 443.96 88.81" xmlns="http://www.w3.org/2000/svg">
      <g [style.fill]="textFill">
        <path d="m99.17,0v68.37s20.55,0,20.55,0V0s-20.55,0-20.55,0Z" />
        <path d="m177.96,0v37.73S151.65,0,151.65,0h-23.73s0,68.37,0,68.37h19.36s0-44.24,0-44.24l30.95,44.24h19.1s0-68.37,0-68.37h-19.38Z" />
        <path d="m256.15,0v37.73S229.85,0,229.85,0h-23.75s0,68.37,0,68.37h19.38s0-44.24,0-44.24l30.93,44.24h19.1s0-68.37,0-68.37h-19.36Z" />
        <path d="m283.48,0v68.37s20.53,0,20.53,0V0s-20.53,0-20.53,0Z" />
        <path d="m423.43,0v25.81s-27.95,0-27.95,0V0s-20.53,0-20.53,0v68.37s20.53,0,20.53,0v-25.81s27.95,0,27.95,0v25.81s20.53,0,20.53,0V0s-20.53,0-20.53,0Z" />
        <path
          d="m364.17,51.63l4.38,16.74h-22.37c-22.47,0-35.87-12.78-35.87-34.18C310.31,12.78,323.72,0,346.18,0h22.37s-4.38,16.74-4.38,16.74h-15.46c-11.42,0-17.44,6.02-17.44,17.44,0,11.41,6.02,17.44,17.44,17.44h15.46Z"
        />
        <polygon
          points="90.92 0 90.92 68.37 71.55 68.37 71.55 19.37 55.04 68.37 35.87 68.37 19.36 19.37 19.36 68.37 0 68.37 0 0 29.05 0 45.46 42.88 61.87 0 90.92 0"
        />
      </g>
      @if (showSubtext) {
      <g [style.fill]="subtextFill">
        <path
          d="m0,83.06c0-3.59,2.41-5.78,6.35-5.78,1.56,0,2.88.34,4.04,1.08l-.83,2.49c-1.02-.59-1.9-.83-2.91-.83-2.19,0-3.44,1.11-3.44,3.01s1.28,3.04,3.54,3.04c1.08,0,2.05-.26,3.09-.85l.92,2.49c-1.13.74-2.55,1.1-4.24,1.1-4.11,0-6.52-2.14-6.52-5.75Z"
        />
        <path
          d="m11.95,83.05c0-3.49,2.44-5.76,6.16-5.76s6.16,2.28,6.16,5.76-2.42,5.76-6.16,5.76-6.16-2.28-6.16-5.76Zm9.12,0c0-1.82-1.18-3.03-2.96-3.03s-2.95,1.21-2.95,3.03,1.18,3.03,2.95,3.03,2.96-1.21,2.96-3.03Z"
        />
        <path d="m37.4,77.48v11.13h-2.87l-5.19-7.19v7.19h-3v-11.13h3.57l4.49,6.22v-6.22h3Z" />
        <path
          d="m39.49,83.06c0-3.59,2.41-5.78,6.35-5.78,1.56,0,2.88.34,4.04,1.08l-.83,2.49c-1.02-.59-1.9-.83-2.91-.83-2.19,0-3.44,1.11-3.44,3.01s1.28,3.04,3.54,3.04c1.08,0,2.05-.26,3.09-.85l.92,2.49c-1.13.74-2.55,1.1-4.24,1.1-4.11,0-6.52-2.14-6.52-5.75Z"
        />
        <path
          d="m59.73,88.61l-2.52-3.85h-2.01v3.85h-3.14v-11.13h6.63c2.73,0,4.16,1.26,4.16,3.65,0,1.74-.75,2.87-2.19,3.36l2.73,4.13h-3.65Zm-4.54-6.4h3.06c.95,0,1.39-.34,1.39-1.08s-.44-1.08-1.39-1.08h-3.06v2.16Z"
        />
        <path d="m68.37,80.07v1.77h6.06v2.39h-6.06v1.8h7.12v2.59h-10.27v-11.13h10.27v2.59h-7.12Z" />
        <path d="m87.94,80.15h-3.95v8.46h-3.14v-8.46h-3.95v-2.67h11.04v2.67Z" />
        <path d="m93,80.07v1.77h6.06v2.39h-6.06v1.8h7.12v2.59h-10.27v-11.13h10.27v2.59h-7.12Z" />
        <path d="m118.68,77.48l-4.5,11.13h-3.24l-4.52-11.13h3.39l2.83,7.65,2.82-7.65h3.23Z" />
        <path d="m120.46,77.48h3.14v11.13h-3.14v-11.13Z" />
        <path
          d="m137.44,85.47c0,2.1-1.41,3.14-4.21,3.14h-6.96v-11.13h6.73c2.72,0,4.08,1.01,4.08,3.04,0,1.2-.56,1.98-1.69,2.39,1.37.36,2.05,1.21,2.05,2.55Zm-8.02-3.62h3.34c.82,0,1.21-.28,1.21-.88s-.39-.92-1.15-.92h-3.41v1.8Zm4.83,3.26c0-.61-.41-.9-1.24-.9h-3.59v1.83h3.65c.79,0,1.18-.31,1.18-.93Z"
        />
        <path
          d="m147.21,88.61l-2.52-3.85h-2.01v3.85h-3.14v-11.13h6.63c2.73,0,4.16,1.26,4.16,3.65,0,1.74-.75,2.87-2.19,3.36l2.73,4.13h-3.65Zm-4.54-6.4h3.06c.95,0,1.39-.34,1.39-1.08s-.44-1.08-1.39-1.08h-3.06v2.16Z"
        />
        <path d="m160.35,86.52h-4.5l-.82,2.1h-3.23l4.76-11.13h3.23l4.76,11.13h-3.39l-.82-2.1Zm-2.24-6.03l-1.33,3.65h2.64l-1.31-3.65Z" />
        <path d="m174.9,80.15h-3.95v8.46h-3.14v-8.46h-3.95v-2.67h11.04v2.67Z" />
        <path
          d="m176.09,83.05c0-3.49,2.44-5.76,6.16-5.76s6.16,2.28,6.16,5.76-2.42,5.76-6.16,5.76-6.16-2.28-6.16-5.76Zm9.12,0c0-1.82-1.18-3.03-2.96-3.03s-2.95,1.21-2.95,3.03,1.18,3.03,2.95,3.03,2.96-1.21,2.96-3.03Z"
        />
        <path
          d="m198.17,88.61l-2.52-3.85h-2.01v3.85h-3.14v-11.13h6.63c2.73,0,4.16,1.26,4.16,3.65,0,1.74-.75,2.87-2.19,3.36l2.73,4.13h-3.65Zm-4.54-6.4h3.06c.95,0,1.39-.34,1.39-1.08s-.44-1.08-1.39-1.08h-3.06v2.16Z"
        />
        <path
          d="m202.92,87.29l1.08-2.34c1.29.88,2.85,1.34,4.58,1.34,1.54,0,2.28-.31,2.28-.95,0-.57-.43-.75-2.41-.93-3.8-.36-5.03-1.23-5.03-3.55s1.92-3.57,5.17-3.57c1.93,0,3.59.46,4.9,1.33l-1.03,2.24c-1.05-.7-2.29-1.05-3.67-1.05s-2.18.31-2.18.93c0,.59.43.77,2.39.95,3.8.34,5.04,1.23,5.04,3.54s-1.83,3.59-5.37,3.59c-2.36,0-4.29-.52-5.76-1.52Z"
        />
        <path d="m228.71,86.52h-4.5l-.82,2.1h-3.23l4.76-11.13h3.23l4.76,11.13h-3.39l-.82-2.1Zm-2.24-6.03l-1.33,3.65h2.64l-1.31-3.65Z" />
        <path d="m245.74,77.48v11.13h-2.87l-5.19-7.19v7.19h-3v-11.13h3.57l4.49,6.22v-6.22h3Z" />
        <path
          d="m259.52,83.05c0,3.45-2.21,5.57-5.81,5.57h-5.3v-11.13h5.3c3.6,0,5.81,2.11,5.81,5.57Zm-3.21,0c0-1.88-1.03-2.9-2.93-2.9h-1.83v5.8h1.83c1.9,0,2.93-1.02,2.93-2.9Z"
        />
        <path
          d="m277.73,83.05c0,3.45-2.21,5.57-5.81,5.57h-5.3v-11.13h5.3c3.6,0,5.81,2.11,5.81,5.57Zm-3.21,0c0-1.88-1.03-2.9-2.93-2.9h-1.83v5.8h1.83c1.9,0,2.93-1.02,2.93-2.9Z"
        />
        <path
          d="m279.22,83.05c0-3.49,2.44-5.76,6.16-5.76s6.16,2.28,6.16,5.76-2.42,5.76-6.16,5.76-6.16-2.28-6.16-5.76Zm9.12,0c0-1.82-1.18-3.03-2.96-3.03s-2.95,1.21-2.95,3.03,1.18,3.03,2.95,3.03,2.96-1.21,2.96-3.03Z"
        />
        <path d="m309.34,77.48l-3.42,11.13h-2.96l-2.06-6.81-2.06,6.81h-3.01l-3.42-11.13h3.31l1.87,6.88,2.08-6.88h2.6l2.08,6.88,1.87-6.88h3.14Z" />
        <path d="m314.28,80.07v1.77h6.06v2.39h-6.06v1.8h7.12v2.59h-10.27v-11.13h10.27v2.59h-7.12Z" />
        <path d="m333.51,85.94v2.67h-9.94v-11.13h3.14v8.46h6.79Z" />
        <path
          d="m351.09,81.29c0,2.49-1.51,3.81-4.37,3.81h-3.11v3.5h-3.14v-11.13h6.25c2.87,0,4.37,1.31,4.37,3.81Zm-3.21,0c0-.83-.47-1.2-1.54-1.2h-2.73v2.39h2.73c1.06,0,1.54-.38,1.54-1.2Z"
        />
        <path d="m353.08,77.48h3.14v11.13h-3.14v-11.13Z" />
        <path d="m369.95,77.48v11.13h-2.87l-5.19-7.19v7.19h-3v-11.13h3.57l4.49,6.22v-6.22h3Z" />
        <path
          d="m388.75,83.05c0,3.45-2.21,5.57-5.81,5.57h-5.3v-11.13h5.3c3.6,0,5.81,2.11,5.81,5.57Zm-3.21,0c0-1.88-1.03-2.9-2.93-2.9h-1.83v5.8h1.83c1.9,0,2.93-1.02,2.93-2.9Z"
        />
        <path
          d="m398.5,88.61l-2.52-3.85h-2.01v3.85h-3.14v-11.13h6.63c2.73,0,4.16,1.26,4.16,3.65,0,1.74-.75,2.87-2.19,3.36l2.73,4.13h-3.65Zm-4.54-6.4h3.06c.95,0,1.39-.34,1.39-1.08s-.44-1.08-1.39-1.08h-3.06v2.16Z"
        />
        <path d="m404,77.48h3.14v11.13h-3.14v-11.13Z" />
        <path d="m419.75,85.94v2.67h-9.94v-11.13h3.14v8.46h6.79Z" />
        <path d="m431.62,85.94v2.67h-9.94v-11.13h3.14v8.46h6.79Z" />
        <path
          d="m432.82,87.29l1.08-2.34c1.29.88,2.85,1.34,4.58,1.34,1.54,0,2.28-.31,2.28-.95,0-.57-.43-.75-2.41-.93-3.8-.36-5.03-1.23-5.03-3.55s1.92-3.57,5.17-3.57c1.93,0,3.59.46,4.9,1.33l-1.03,2.24c-1.05-.7-2.29-1.05-3.67-1.05s-2.18.31-2.18.93c0,.59.43.77,2.39.95,3.8.34,5.04,1.23,5.04,3.54s-1.83,3.59-5.37,3.59c-2.36,0-4.29-.52-5.76-1.52Z"
        />
      </g>
      }
    </svg>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class LogoComponent {
  @Input() public center: boolean = false;
  @Input() public color: string = 'white';
  @Input() public subtextFill: string = '#008658';
  @Input() public textFill: string = '#000000';
  @Input() public showSubtext: boolean = false;
  @Input() public width: any = 200;
}
