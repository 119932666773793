import { Component } from '@angular/core';

@Component({
  selector: 'main-entry',
  template: `
    <router-outlet></router-outlet>
    <p-toast position="bottom-right"></p-toast>
  `,
  styles: [],
})
export class MainEntryComponent {
  constructor() {}
}
