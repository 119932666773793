import { Component, OnInit } from '@angular/core';
import { AppwriteFunctionsService, AuthService } from '@appwrite/angular';
import { AwFunctions } from '@mm-mono/data/appwrite';
import { debugStream, rxJsonParse } from '@mm-mono/utilities';
import { Document } from '@sap/common';
import { Select } from 'odata-query';
import { PaginatorState } from 'primeng/paginator';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'account-history',
  templateUrl: 'account-history.component.html',
})
export class AccountHistoryComponent implements OnInit {
  public orders: Document[] = [];
  public count: number = 0;
  public first: number = 1;
  public rows: number = 10;

  constructor(public authService: AuthService, private functionsService: AppwriteFunctionsService) {}

  ngOnInit() {
    this.get();
  }

  public async get(page: number = this.first) {
    const select: Select<Document> = ['DocDate', 'DocNum', 'DocTotal', 'DocumentLines', 'Address', 'Address2', 'AddressExtension', 'CardCode', 'CardName'];
    const orderBy = 'DocDate desc, DocNum desc';
    const query = { select, count: true, orderBy, top: this.rows, skip: page * this.rows };

    from(this.functionsService.createExecution(AwFunctions.sapServiceLayer, JSON.stringify(query), false, `/Orders`, 'GET'))
      .pipe(
        rxJsonParse('responseBody'),
        map((d) => d?.responseBody?.data),
        debugStream('Orders')
      )
      .subscribe((data) => {
        this.count = data?.['odata.count'];
        this.orders = data?.value;
      });
  }

  pageChanged(event: PaginatorState): void {
    this.get(event.page + 1);
  }
}
