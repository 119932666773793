<home-carousel></home-carousel>

<section class="py-12" id="applications">
  <h1 class="text-center text-3xl">Minnich's Latest</h1>
  <p class="text-center text-xs uppercase">New products and industry news</p>
  <div class="mx-auto mt-10 grid grid-cols-1 px-4 md:grid-cols-3 lg:px-12">
    <div *ngFor="let p of features" class="px-4">
      <div class="block border">
        <img [src]="p.image" class="aspect-[16/9]" width="100%" />
        <div class="p-4">
          <h3 class="m-0 p-0 font-semibold uppercase">{{ p.title }}</h3>
          <p class="m-0 mb-4 p-0">{{ p.desc }}</p>
          <a
            [routerLink]="p.link"
            class="bg-accent-500 hover:bg-accent-400 focus-visible:outline-accent-500 rounded-none px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-neutral-100 py-12" id="tagline">
  <div class="container">
    <h1 class="mb-5 text-center text-3xl">HERE AT MINNICH</h1>
    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2">
      <p class="text-justify text-base leading-8">
        Minnich Manufacturing is an industry leader in the production of Dowel Pin Drills, Concrete Paving Vibrators and Vibrator Monitoring Systems. Minnich
        has a rich history, and we are grateful for the people who have been a part of that history. The team’s experience and dedication has been a consistent
        factor throughout the growth of Minnich for over 50 years. The Minnich family takes pride in the private, family-run business today. We focus on trust
        and producing high-quality, long-lasting products, along with providing around-the-clock customer service.
      </p>
      <div class="">
        <iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="col-span-10 col-start-2 block aspect-video w-full"
          frameborder="0"
          src="https://www.youtube.com/embed/fa7FmP5LMHo"
          width="560"
        >
        </iframe>
      </div>
    </div>
  </div>
</section>
<home-map-component></home-map-component>
